import React from "react";
import Head from "../../components/head.js";
import { FaGlobe } from "@react-icons/all-files/fa/FaGlobe";
import { OutboundLink } from "gatsby-plugin-google-analytics";

import Layout from "../../components/layout";

const Ana = () => {
  return (
    <Layout>
      <Head title="Ana Gilbert" />
      <h3>Ana Gilbert</h3>
      <h4>
        <FaGlobe style={{ marginRight: 7, paddingTop: 5 }} />
        In Between
      </h4>
      <p>
        <OutboundLink href="https://www.lensculture.com/ana-gilbert">
          Lens Culture
        </OutboundLink>
      </p>
      <p>
        <OutboundLink href="https://anagilbertphotography.blog/">
          Photography Blog
        </OutboundLink>
      </p>
      {/* <p>
        <OutboundLink href="https://ello.co/anagilbert">Instagram</OutboundLink>
      </p>
      <p>
        <OutboundLink href="https://ello.co/anagilbert">Ello</OutboundLink>
      </p> */}

      <p>
        I am a photographer based in Rio de Janeiro, Brazil. Since 2005, I
        develop an authorial work in photography in paralel to my activities as
        a clinical psychologist and researcher.
      </p>
      <p>
        My interest in this form of artistic expression arose from studies on
        practices of looking; my involvement with images, words and imagination
        took me to an intersection between photography and literature, working
        with images as narratives.
      </p>
      <p>
        Body, dance (movement) and fragments are themes that are dear to me, and
        self portraiture is a significant exercise in my pathway in photography.
      </p>

      {/* <img
        style={{ marginBottom: 5 }}
        src="https://assets0.ello.co/uploads/asset/attachment/15406620/ello-optimized-001e0579.jpg"
        alt="Ana Gilbert art photography"
      /> */}
      <p>
        <em>
          perhaps this is what death is: an absence of sound and image that
          envelops us, a clotted abyss where there is only suspension. no needs,
          no imperatives. maybe death is just stillness. and for a moment this
          thought calms me down.
        </em>
      </p>
      {/* <img
        src="https://assets2.ello.co/uploads/asset/attachment/15408555/ello-optimized-4c02a351.jpg"
        alt="Ana Gilbert art photography"
      />
      <img
        src="https://assets0.ello.co/uploads/asset/attachment/14742611/ello-optimized-71babdbe.jpg"
        alt="Ana Gilbert art photography"
      />
      <img
        src="https://assets1.ello.co/uploads/asset/attachment/14656840/ello-optimized-d89fe3c4.jpg"
        alt="Ana Gilbert art photography"
      />
      <img
        src="https://assets1.ello.co/uploads/asset/attachment/10902636/ello-optimized-183144b5.jpg"
        alt="Ana Gilbert art photography"
      />
      <img
        src="https://assets0.ello.co/uploads/asset/attachment/11146553/ello-optimized-10630b91.jpg"
        alt="Ana Gilbert art photography"
      />
      <img
        src="https://assets1.ello.co/uploads/asset/attachment/11164121/ello-optimized-a5975db5.jpg"
        alt="Ana Gilbert art photography"
      /> */}
    </Layout>
  );
};

export default Ana;
